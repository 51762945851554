import Command from '../../actions/command';
import {call, fork, put, take} from 'redux-saga/effects';
import {Action} from 'redux-actions';
import Event from '../../actions/event';
import Api from '../../../api';
import {ArchiveProjectPayload} from '../../actions/customerCommand';

const onArchiveProject = function*(data: ArchiveProjectPayload) {
    try {
        yield call(Api.Command.Project.archiveProject, {
            ...data,
        });

        yield put(Event.Customer.projectArchived({
            ...data,
        }));

        yield put(Command.Notification.enqueueNotification({
            severity: 'success',
            message: 'Das Projekt wurde archiviert.',
        }));
    } catch (e) {
        yield put(Command.Notification.enqueueNotification({
            severity: 'error',
            message: 'Das Projekt konnte nicht archiviert werden.',
        }));
    }
};

export function* archiveProjectFlow() {
    while (true) {
        const action: Action<ArchiveProjectPayload> = yield take(Command.Customer.archiveProject.toString());
        yield fork(onArchiveProject, action.payload);
    }
}
