import Command from '../../actions/command';
import {call, fork, put, take} from 'redux-saga/effects';
import Api from '../../../api';
import {Action} from 'redux-actions';
import {RequestDateRangeExportPayload} from '../../actions/DateRangeExportCommand';

const onFetchRequestDateRangeExport = function*(data: RequestDateRangeExportPayload) {
    try {
        const requestDateRangeExport: string = yield call(Api.Query.requestDateRangeExport, data.projectId);

        /* eslint-disable-next-line max-len */
        const url = `${window.location.origin}/api/export/date-range/${data.projectId}/pdf?start=${data.startParam}&end=${data.endParam}&jwt=${requestDateRangeExport}`;

        window.open(url, '_blank');

    } catch (e) {
        yield put(Command.Notification.enqueueNotification({
            severity: 'error',
            message: 'Der Token konnte nicht generiert werden..',
        }));
    }
};

export function* fetchRequestDateRangeExport() {
    while (true) {
        const action: Action<RequestDateRangeExportPayload> =
            yield take(Command.DateRangeExport.requestDateRangeExport.toString());
        yield fork(onFetchRequestDateRangeExport, action.payload);
    }
}

