import Command from '../../actions/command';
import {call, fork, put, take} from 'redux-saga/effects';
import Event from '../../actions/event';
import Api from '../../../api';
import {WorkingTime} from '../../types/WorkingTime';

const onFetchAllWorkingTimeList = function*() {
    try {
        const allWorkingTimeList: WorkingTime[] = yield call(Api.Query.allWorkingTimeList);

        yield put(Event.WorkingTime.allWorkingTimeListFetched({
            allWorkingTimeList,
        }));
    } catch (e) {
        yield put(Command.Notification.enqueueNotification({
            severity: 'error',
            message: 'Die List aller Arbeitszeiten konnte nicht geladen werden.',
        }));
    }
};

export function* fetchAllWorkingTimeListFlow() {
    while (true) {
        yield take(Command.WorkingTime.fetchAllWorkingTimeList.toString());
        yield fork(onFetchAllWorkingTimeList);
    }
}
