import {Avatar, Tooltip} from '@mui/material';
import {User} from '../../state/types/User';
import {useDispatch, useSelector} from 'react-redux';
import {Selector} from '../../state/selector';
import {useEffect} from 'react';
import Command from '../../state/actions/command';

interface UserAvatarProps {
    userId: string;
}

const UserAvatar = (props: UserAvatarProps) => {
    const dispatch = useDispatch();
    const users = useSelector(Selector.User.users);
    const user: User|undefined = users[props.userId];

    useEffect(() => {
        if (!user) {
            dispatch(Command.User.fetchUser({ userId: props.userId }));
        }
    }, [props.userId]);

    const initials = user
        ? user.firstName.slice(0, 1) + user.lastName.slice(0, 1)
        : '';

    return (
        <Tooltip title={user ? user.firstName + ' ' + user.lastName : ''}>
            <Avatar sx={{ marginRight: 0.5 }}>
                {initials}
            </Avatar>
        </Tooltip>
    );
};

export default UserAvatar;
