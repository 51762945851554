import UserEvent from './userEvent';
import CustomerEvent from './customerEvent';
import NotificationEvent from './notificationEvent';
import WorkingTimeEvent from './workingTimeEvent';

const Event = {
    User: UserEvent,
    Customer: CustomerEvent,
    WorkingTime: WorkingTimeEvent,
    Notification: NotificationEvent,
};

export default Event;
