import Command from '../../actions/command';
import {call, fork, put, take} from 'redux-saga/effects';
import {Action} from 'redux-actions';
import Event from '../../actions/event';
import Api from '../../../api';
import {UnarchiveProjectPayload} from '../../actions/customerCommand';

const onUnarchiveProject = function*(data: UnarchiveProjectPayload) {
    try {
        yield call(Api.Command.Project.unarchiveProject, {
            ...data,
        });

        yield put(Event.Customer.projectUnarchived({
            ...data,
        }));

        yield put(Command.Notification.enqueueNotification({
            severity: 'success',
            message: 'Das Projekt ist nicht mehr archiviert',
        }));
    } catch (e) {
        yield put(Command.Notification.enqueueNotification({
            severity: 'error',
            message: 'Die Archivierung des Projektes konnte nicht aufgehoben werden.',
        }));
    }
};

export function* unarchiveProjectFlow() {
    while (true) {
        const action: Action<UnarchiveProjectPayload> = yield take(Command.Customer.unarchiveProject.toString());
        yield fork(onUnarchiveProject, action.payload);
    }
}
