import {Navigate, Route, Routes} from 'react-router';
import PageLayout from './layout/PageLayout';
import CustomerListPage from './components/pages/CustomerListPage';
import {useDispatch} from 'react-redux';
import {useEffect} from 'react';
import Command from './state/actions/command';
import ProjectDetailsPage from './components/pages/ProjectDetailsPage';

const Main = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(Command.Customer.fetchCustomerList({}));
        dispatch(Command.WorkingTime.fetchAllWorkingTimeList({}));
    }, []);

    return (
        <Routes>
            <Route path={'/'} element={<PageLayout />}>
                <Route path={'/dashboard'} element={<div>DASH</div>} />
                <Route path={'/customers'} element={<CustomerListPage />} />
                <Route path={'/customers/:customerId/projects/:projectId'} element={<ProjectDetailsPage />} />
                <Route index={true} element={<Navigate to={'/customers'} replace={true} />} />
            </Route>
        </Routes>
    );
};

export default Main;
