import {createAction} from 'redux-actions';

const createUserCommand = <T>(type: string) => createAction<T>('_C/US/' + type);

export interface FetchUserPayload {
    userId: string;
}

const UserCommand = {
    fetchUser: createUserCommand<FetchUserPayload>('FETCH_USER'),
};

export default UserCommand;
