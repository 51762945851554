import Command from '../../actions/command';
import {call, fork, put, take} from 'redux-saga/effects';
import {Action} from 'redux-actions';
import Event from '../../actions/event';
import Api from '../../../api';
import {AdjustWorkingTimePayload} from '../../actions/workingTimeCommand';

const onAdjustWorkingTime = function*(data: AdjustWorkingTimePayload) {
    try {
        yield call(Api.Command.WorkingTime.adjustWorkingTime, {
            workingTimeId: data.workingTimeId,
            start: data.start,
            end: data.end,
            note: data.note,
            tags: data.tags,
        });

        yield put(Event.WorkingTime.workingTimeAdjusted({
            workingTimeId: data.workingTimeId,
            projectId: data.projectId,
            start: data.start,
            end: data.end,
            note: data.note,
            tags: data.tags,
        }));

        yield put(Event.Customer.workingTimeAdjusted({
            projectId: data.projectId,
            start: data.start,
            end: data.end,
            oldStart: data.oldStart,
            oldEnd: data.oldEnd,
        }));

        yield put(Command.Notification.enqueueNotification({
            severity: 'success',
            message: 'Die Arbeitszeit wurde angepasst.',
        }));
    } catch (e) {
        yield put(Command.Notification.enqueueNotification({
            severity: 'error',
            message: 'Die Arbeitszeit konnte nicht angepasst werden.',
        }));
    }
};

export function* adjustWorkingTimeFlow() {
    while (true) {
        const action: Action<AdjustWorkingTimePayload> = yield take(Command.WorkingTime.adjustWorkingTime.toString());
        yield fork(onAdjustWorkingTime, action.payload);
    }
}
