import {Box} from '@mui/material';
import {useParams} from 'react-router';
import {useSelector} from 'react-redux';
import {Selector} from '../../state/selector';
import {useEffect, useState} from 'react';
import EditWorkingTimeDialog from './projectDetails/EditWorkingTimeDialog';
import {DateTime, Interval} from 'luxon';
import {WorkingTime} from '../../state/types/WorkingTime';
import TrackTimeDialog from './customerList/TrackTimeDialog';
import WorkingTimeFilterBar from './projectDetails/WorkingTimeFilterBar';
import ProjectDetailTable from './projectDetails/ProjectDetailTable';
import ArchiveProjectDialog from './customerList/ArchiveProjectDialog';
import Command from '../../state/actions/command';
import {useDispatch} from 'react-redux';

const ProjectDetailsPage = () => {
    const dispatch = useDispatch();

    const params = useParams<{ customerId: string, projectId: string }>();
    const customer = useSelector((state: any) => Selector.Customer.customer(state, params.customerId || ''));
    const project = useSelector((state: any) => Selector.Customer.project(state, params.projectId || ''));
    const workingTimeList = useSelector(
        (state: any) => Selector.WorkingTime.workingTimeList(state, params.projectId || ''),
    );
    const [filteredWorkingTimeList, setFilteredWorkingTimeList] = useState<WorkingTime[]>([]);
    const [workingTimeToEdit, setWorkingTimeToEdit] = useState<string>();
    const [editWorkingTimeDialogOpen, setEditWorkingTimeDialogOpen] = useState<boolean>(false);
    const [trackTimeDialogOpen, setTrackTimeDialogOpen] = useState<boolean>(false);
    const [archiveProjectDialogOpen, setArchiveProjectDialogOpen] = useState<boolean>(false);
    const [start, setStart] = useState<DateTime|null>(DateTime.now().minus({ day: 5 }).startOf('day'));
    const [end, setEnd] = useState<DateTime|null>(DateTime.now().startOf('minute'));
    const [filterIsSetFlag, setFilterIsSetFlag] = useState<boolean>(true);

    const updateLocalData = () => {
        dispatch(Command.Customer.fetchCustomerList({}));
        dispatch(Command.WorkingTime.fetchAllWorkingTimeList({}));
    };

    const openEditWorkingTimeDialog = (workingTimeId: string) => {
        setWorkingTimeToEdit(workingTimeId);
        setEditWorkingTimeDialogOpen(true);
    };

    const closeEditWorkingTimeDialog = () => {
        setEditWorkingTimeDialogOpen(false);
        setWorkingTimeToEdit(undefined);
    };

    const openTrackTimeDialog = () => {
        setTrackTimeDialogOpen(true);
    };

    const closeTrackTimeDialog = () => {
        setTrackTimeDialogOpen(false);
    };

    const openArchiveProjectDialog = () => {
        setArchiveProjectDialogOpen(true);
    };

    const closeArchiveProjectDialog = () => {
        setArchiveProjectDialogOpen(false);
    };

    const exportPdfForSelectedTime = () => {
        if (!project || !start || !end) {
            return;
        }

        const timeSpan = Interval.fromDateTimes(start!, end!).length('days');
        if (1 >= timeSpan || timeSpan > 91) {
            dispatch(Command.Notification.enqueueNotification({
                severity: 'error',
                message: 'Die Zeitspanne des Exports muss zwischen 1 - 90 Tagen liegen.',
            }));
            return;
        };

        const startParam = filterIsSetFlag ? start.toISODate() : '2000-01-01';
        const endParam = filterIsSetFlag ? end.toISODate() : DateTime.now().toISODate();
        dispatch(Command.DateRangeExport.requestDateRangeExport({
            projectId: project.projectId,
            startParam: startParam,
            endParam: endParam,
        }));
    };

    const resetFilterDetailPage = () => {
        setStart(DateTime.now().minus({ day: 5 }).startOf('day'));
        setEnd(DateTime.now().startOf('minute'));
        setFilterIsSetFlag(false);
        project!.filterTime = project!.total;
        setFilteredWorkingTimeList(workingTimeList ? sortListStartDesc(workingTimeList) : []);
        updateLocalData();
    };

    const filterWorkingTime = () => {
        filterWorkingTimeList();
        updateLocalData();
    };

    const filterWorkingTimeList = () => {
        if (!workingTimeList || !project) {
            return;
        }
        const filteredList: WorkingTime[] = workingTimeList.filter(
            workingTime => start!.toJSDate() <= workingTime.start && workingTime.start <= end!.toJSDate(),
        );
        project.filterTime = filteredProjectTime();
        setFilteredWorkingTimeList(sortListStartDesc(filteredList));
        setFilterIsSetFlag(true);
    };

    const filteredProjectTime = (): number => {
        if (!start || !end || !workingTimeList) {
            return 0;
        }
        let filteredTime = 0;
        for (const workTime of workingTimeList) {
            if (start!.toJSDate() <= workTime.start && workTime.start <= end!.toJSDate()) {
                const timeDiff = ((workTime.end.getTime() - workTime.start.getTime()) / (60 * 60 * 1000));
                filteredTime += parseFloat(timeDiff.toFixed(2));
            }
        }
        return filteredTime;
    };

    const sortListStartDesc = (unsortedList: WorkingTime[]) => {
        return unsortedList.sort(
            (le1, le2) =>
                (le1.start < le2.start) ? 1 : (le1.start > le2.start) ? -1 : 0);
    };

    useEffect(() => {
        if (!filterIsSetFlag) {
            project!.filterTime = project!.total;
        }
        filterIsSetFlag ? filterWorkingTimeList() :
            setFilteredWorkingTimeList(workingTimeList ? sortListStartDesc(workingTimeList) : []);
    }, [workingTimeList, project, customer]);

    useEffect(() => {
        updateLocalData();
    }, []);

    if (!customer || !project) {
        return null;
    }

    return (
        <Box>
            <WorkingTimeFilterBar
                start={start}
                end={end}
                onChangeStart={setStart}
                onChangeEnd={setEnd}
                onClickFilterList={filterWorkingTime}
                onClickResetFilter={resetFilterDetailPage}
                filterIsSet={filterIsSetFlag}
            />
            <ProjectDetailTable
                customer={customer}
                project={project}
                workingTimeList={filteredWorkingTimeList}
                onClickTrackTimeDialog={openTrackTimeDialog}
                onClickEditWorkingTimeDialog={openEditWorkingTimeDialog}
                onClickArchiveProjectDialog={openArchiveProjectDialog}
                onClickExportPdfForSelectedTime={exportPdfForSelectedTime}
            />
            <EditWorkingTimeDialog
                open={editWorkingTimeDialogOpen}
                onClose={closeEditWorkingTimeDialog}
                workingTime={workingTimeList?.find(workingTime => workingTime.workingTimeId === workingTimeToEdit)}
            />
            <TrackTimeDialog
                open={trackTimeDialogOpen}
                onClose={closeTrackTimeDialog}
                projectId={project.projectId}
            />
            <ArchiveProjectDialog
                open={archiveProjectDialogOpen}
                onClose={closeArchiveProjectDialog}
                projectId={project.projectId}
            />
        </Box>
    );
};

export default ProjectDetailsPage;
