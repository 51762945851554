import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, TextField,
} from '@mui/material';
import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import Command from '../../../state/actions/command';

interface AssignUserToProjectDialogProps {
    open: boolean;
    onClose: () => void;
    projectId?: string;
}

const AssignUserToProjectDialog = (props: AssignUserToProjectDialogProps) => {
    const dispatch = useDispatch();
    const [email, setEmail] = useState<string>('');

    useEffect(() => {
        setEmail('');
    }, [props.open]);

    const assignUserToProject = () => {
        dispatch(Command.Customer.assignUserToProject({
            projectId: props.projectId!,
            email,
        }));
        props.onClose();
    };

    return (
        <Dialog open={props.open} fullWidth={true} onClose={props.onClose}>
            <DialogTitle>Mitarbeiter hinzufügen</DialogTitle>
            <DialogContent>
                <TextField
                    label={'E-Mail'}
                    value={email}
                    onChange={event => setEmail(event.target.value)}
                    sx={{ marginTop: 1 }}
                    fullWidth={true}
                    type={'email'}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>
                    Abbrechen
                </Button>
                <Button variant={'contained'} startIcon={<AddIcon />} onClick={assignUserToProject}>
                    Hinzufügen
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AssignUserToProjectDialog;
