import Command from '../../actions/command';
import {call, fork, put, take} from 'redux-saga/effects';
import Event from '../../actions/event';
import Api from '../../../api';
import {Action} from 'redux-actions';
import {FetchUserPayload} from '../../actions/userCommand';
import {User} from '../../types/User';

const onFetchUser = function*(data: FetchUserPayload) {
    try {
        const user: User = yield call(Api.Query.user, data.userId);

        yield put(Event.User.userFetched({ user }));
    } catch (e) {
        /* Ignore for now */
    }
};

export function* fetchUserFlow() {
    while (true) {
        const action: Action<FetchUserPayload> = yield take(Command.User.fetchUser.toString());
        yield fork(onFetchUser, action.payload);
    }
}
