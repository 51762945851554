import {Box, Button, TextField, Theme} from '@mui/material';
import {DateTime} from 'luxon';
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker';
import MoreTimeIcon from '@mui/icons-material/MoreTime';

interface WorkingTimeFilterBarProps {
    start: DateTime|null;
    end: DateTime|null;
    onChangeStart: (newValue: DateTime|null) => void;
    onChangeEnd: (newValue: DateTime|null) => void;

    onClickFilterList: () => void;
    onClickResetFilter: () => void;
    filterIsSet: boolean;
}

const styles = {
    startEndWrapper: (theme: Theme) => ({
        marginTop: 1,
        marginBottom: 5,
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            alignItems: 'center',
        },
    }),
    endWrapper: (theme: Theme) => ({
        [theme.breakpoints.down('md')]: {
            marginTop: 1,
        },
        [theme.breakpoints.up('md')]: {
            marginLeft: 1,
        },
    }),
};

const WorkingTimeFilterBar = (props: WorkingTimeFilterBarProps) => {
    return (
        <Box sx={styles.startEndWrapper}>
            <Box>
                <DesktopDatePicker
                    label={'Start'}
                    value={props.start}
                    onChange={props.onChangeStart}
                    renderInput={(params) => <TextField {...params} />}
                    maxDate={DateTime.now()}
                    inputFormat={'dd.MM.yyyy'}
                    mask={'__/__/____'}
                    views={['day']}
                />
            </Box>
            <Box sx={styles.endWrapper}>
                <DesktopDatePicker
                    label={'Ende'}
                    value={props.end}
                    onChange={props.onChangeEnd}
                    renderInput={(params) => <TextField {...params} />}
                    maxDate={DateTime.now()}
                    minDate={props.start!}
                    inputFormat={'dd.MM.yyyy'}
                    mask={'__/__/____'}
                    views={['day']}
                />
            </Box>
            &nbsp;&nbsp;&nbsp;
            <Button
                variant={'contained'}
                startIcon={<MoreTimeIcon />}
                children={'Filtern'}
                onClick={props.onClickFilterList}
            />
            &nbsp;&nbsp;&nbsp;
            <Button
                variant={'contained'}
                startIcon={<MoreTimeIcon />}
                children={'Zurücksetzen'}
                onClick={props.onClickResetFilter}
                disabled={!props.filterIsSet}
            />
        </Box>
    );
};

export default WorkingTimeFilterBar;
