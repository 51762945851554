import {ReduxState} from '../reducer';
import {createSelector} from 'reselect';
import {User} from '../types/User';

const userId = (state: ReduxState): string => state.user.userId;
const roles = (state: ReduxState): string[] => state.user.roles;
const users = (state: ReduxState): Record<string, User> => state.user.users;

const isAdmin = createSelector(
    [roles],
    roles => roles.includes('admin'),
);

const user = createSelector(
    [users, (_: any, userId: string) => userId],
    (users, userId) => users[userId],
);

const UserSelector = {
    userId,
    roles,
    users,

    isAdmin,
    user,
};

export default UserSelector;
