import {createAction} from 'redux-actions';
import {User} from '../types/User';

const createUserEvent = <T>(type: string) => createAction<T>('_E/US/' + type);

export interface UserAuthenticatedPayload {
    userId: string;
    roles: string[];
}

export interface UserFetchedPayload {
    user: User;
}

const UserEvent = {
    userAuthenticated: createUserEvent<UserAuthenticatedPayload>('USER_AUTHENTICATED'),
    userFetched: createUserEvent<UserFetchedPayload>('USER_FETCHED'),
};

export default UserEvent;
