import {Action, handleActions} from 'redux-actions';
import Event from '../actions/event';
import {Customer} from '../types/Customer';
import {
    CustomerListFetchedPayload,
    NewCustomerCreatedPayload,
    NewProjectCreatedPayload,
    ProjectArchivedPayload,
    ProjectUnarchivedPayload,
    UserAssignedToProjectPayload,
    WorkingTimeAdjustedPayload,
    WorkingTimeRecordedPayload,
} from '../actions/customerEvent';

export interface CustomerState {
    customerList: Customer[];
}

export const customerInitialState: CustomerState = {
    customerList: [],
};

/* eslint-disable max-len */
export const customerReducer = handleActions<CustomerState, any>(
    {
        [Event.Customer.customerListFetched.toString()]: (state, action: Action<CustomerListFetchedPayload>): CustomerState => {
            return {
                ...state,
                customerList: action.payload.customerList,
            };
        },
        [Event.Customer.newCustomerCreated.toString()]: (state, action: Action<NewCustomerCreatedPayload>): CustomerState => {
            const newCustomerList = [
                ...state.customerList,
                {
                    customerId: action.payload.customerId,
                    name: action.payload.name,
                    projects: [],
                },
            ];

            newCustomerList.sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0);

            return {
                ...state,
                customerList: newCustomerList,
            };
        },
        [Event.Customer.newProjectCreated.toString()]: (state, action: Action<NewProjectCreatedPayload>): CustomerState => {
            return {
                ...state,
                customerList: state.customerList.map(customer => {
                    if (customer.customerId !== action.payload.customerId) {
                        return customer;
                    }

                    return {
                        ...customer,
                        projects: [
                            ...customer.projects,
                            {
                                projectId: action.payload.projectId,
                                name: action.payload.name,
                                employees: [],
                                total: 0,
                                last30Days: 0,
                                archived: false,
                            },
                        ],
                    };
                }),
            };
        },
        [Event.Customer.userAssignedToProject.toString()]: (state, action: Action<UserAssignedToProjectPayload>): CustomerState => {
            return {
                ...state,
                customerList: state.customerList.map(customer => {
                    const project = customer.projects.find(project => project.projectId === action.payload.projectId);
                    if (!project) {
                        return customer;
                    }

                    return {
                        ...customer,
                        projects: customer.projects.map(project => {
                            if (project.projectId !== action.payload.projectId) {
                                return project;
                            }

                            return {
                                ...project,
                                employees: [...project.employees, action.payload.userId],
                            };
                        }),
                    };
                }),
            };
        },
        [Event.Customer.projectArchived.toString()]: (state, action: Action<ProjectArchivedPayload>): CustomerState => {
            return {
                ...state,
                customerList: state.customerList.map(customer => {
                    const project = customer.projects.find(project => project.projectId === action.payload.projectId);
                    if (!project) {
                        return customer;
                    }

                    return {
                        ...customer,
                        projects: customer.projects.map(project => {
                            if (project.projectId !== action.payload.projectId) {
                                return project;
                            }

                            return {
                                ...project,
                                archived: true,
                            };
                        }),
                    };
                }),
            };
        },
        [Event.Customer.projectUnarchived.toString()]: (state, action: Action<ProjectUnarchivedPayload>): CustomerState => {
            return {
                ...state,
                customerList: state.customerList.map(customer => {
                    const project = customer.projects.find(project => project.projectId === action.payload.projectId);
                    if (!project) {
                        return customer;
                    }

                    return {
                        ...customer,
                        projects: customer.projects.map(project => {
                            if (project.projectId !== action.payload.projectId) {
                                return project;
                            }

                            return {
                                ...project,
                                archived: false,
                            };
                        }),
                    };
                }),
            };
        },
        [Event.Customer.workingTimeRecorded.toString()]: (state, action: Action<WorkingTimeRecordedPayload>): CustomerState => {
            return {
                ...state,
                customerList: state.customerList.map(customer => {
                    const project = customer.projects.find(project => project.projectId === action.payload.projectId);
                    if (!project) {
                        return customer;
                    }

                    const startTime = action.payload.start.getTime();
                    const totalTimeDiff = (action.payload.end.getTime() - startTime) / (60 * 60 * 1000);
                    const recentTimeDiff = ((new Date().getTime() - startTime) / (24 * 60 * 60 * 1000)) < 5 ?
                        totalTimeDiff : 0;

                    return {
                        ...customer,
                        projects: customer.projects.map(project => {
                            if (project.projectId !== action.payload.projectId) {
                                return project;
                            }

                            return {
                                ...project,
                                total: project.total + totalTimeDiff,
                                last30Days: project.last30Days + recentTimeDiff,
                                filterTime: (project.filterTime ? project.filterTime : project.total) + totalTimeDiff,
                            };
                        }),
                    };
                }),
            };
        },
        [Event.Customer.workingTimeAdjusted.toString()]: (state, action: Action<WorkingTimeAdjustedPayload>): CustomerState => {
            return {
                ...state,
                customerList: state.customerList.map(customer => {
                    const project = customer.projects.find(project => project.projectId === action.payload.projectId);
                    if (!project) {
                        return customer;
                    }

                    const startTime = action.payload.start.getTime();
                    const totalTimeDiff = (action.payload.end.getTime() - startTime) / (60 * 60 * 1000);
                    const recentTimeDiff = ((new Date().getTime() - startTime) / (24 * 60 * 60 * 1000)) < 5 ?
                        totalTimeDiff : 0;

                    const oldStartTime = action.payload.oldStart.getTime();
                    const oldTotalTimeDiff = (action.payload.oldEnd.getTime() - oldStartTime) / (60 * 60 * 1000);
                    const oldRecentTimeDiff = ((new Date().getTime() - oldStartTime) / (24 * 60 * 60 * 1000)) < 5 ?
                        oldTotalTimeDiff : 0;

                    return {
                        ...customer,
                        projects: customer.projects.map(project => {
                            if (project.projectId !== action.payload.projectId) {
                                return project;
                            }

                            return {
                                ...project,
                                total: project.total + totalTimeDiff - oldTotalTimeDiff,
                                last30Days: project.last30Days + recentTimeDiff - oldRecentTimeDiff,
                                filterTime: (project.filterTime ? project.filterTime : project.total) + totalTimeDiff - oldRecentTimeDiff,
                            };
                        }),
                    };
                }),
            };
        },
    },
    customerInitialState,
);
