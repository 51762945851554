import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import Config from '../util/config';
import Keycloak from '../keycloak';
import {
    AdjustWorkingTimeData,
    ArchiveProjectData,
    AssignEmployeeToProjectData,
    CreateNewCustomerData,
    CreateNewProjectData,
    RecordWorkingTimeData,
    RemoveEmployeeFromProjectData,
    RemoveWorkingTimeTagData,
    TagWorkingTimeData,
    UnarchiveProjectData,
} from './types';
import {Customer} from '../state/types/Customer';
import {User} from '../state/types/User';
import {WorkingTime} from '../state/types/WorkingTime';

const configuredAxios = axios.create({
    baseURL: Config.api.baseUrl,
});

const sendApiRequest = async (config: AxiosRequestConfig): Promise<AxiosResponse> => {
    await Keycloak.refreshToken(10);

    // @ts-ignore
    configuredAxios.defaults.headers.Authorization = `Bearer ${Keycloak.instance.token}`;
    return configuredAxios(config);
};

const sendMessageToMessageBox = async (name: string, payload?: object): Promise<AxiosResponse> => {
    return sendApiRequest({
        url: `/messagebox/${name}`,
        method: 'post',
        data: payload,
    });
};

const Api = {
    Command: {
        Customer: {
            createNewCustomer: async (payload: CreateNewCustomerData): Promise<void> => {
                await sendMessageToMessageBox('CreateNewCustomer', payload);
            },
        },
        Project: {
            createNewProject: async (payload: CreateNewProjectData): Promise<void> => {
                await sendMessageToMessageBox('CreateNewProject', payload);
            },
            assignEmployeeToProject: async (payload: AssignEmployeeToProjectData): Promise<void> => {
                await sendMessageToMessageBox('AssignEmployeeToProject', payload);
            },
            removeEmployeeFromProject: async (payload: RemoveEmployeeFromProjectData): Promise<void> => {
                await sendMessageToMessageBox('RemoveEmployeeFromProject', payload);
            },
            archiveProject: async (payload: ArchiveProjectData): Promise<void> => {
                await sendMessageToMessageBox('ArchiveProject', payload);
            },
            unarchiveProject: async (payload: UnarchiveProjectData): Promise<void> => {
                await sendMessageToMessageBox('UnarchiveProject', payload);
            },
        },
        WorkingTime: {
            recordWorkingTime: async (payload: RecordWorkingTimeData): Promise<void> => {
                await sendMessageToMessageBox('RecordWorkingTime', payload);
            },
            adjustWorkingTime: async (payload: AdjustWorkingTimeData): Promise<void> => {
                await sendMessageToMessageBox('AdjustWorkingTime', payload);
            },
            tagWorkingTime: async (payload: TagWorkingTimeData): Promise<void> => {
                await sendMessageToMessageBox('TagWorkingTime', payload);
            },
            removeWorkingTimeTag: async (payload: RemoveWorkingTimeTagData): Promise<void> => {
                await sendMessageToMessageBox('RemoveWorkingTimeTag', payload);
            },
        },
    },
    Query: {
        customerList: async (): Promise<Customer[]> => {
            const response = await sendMessageToMessageBox('CustomerList');

            return response.data.map((customerData: any): Customer => ({
                customerId: customerData.customerId,
                name: customerData.name,
                projects: customerData.projects.map((project: any) => ({
                    ...project,
                })),
            }));
        },
        user: async (userId: string): Promise<User> => {
            const response = await sendMessageToMessageBox('User', { userId });

            return response.data;
        },
        userByEmail: async (email: string): Promise<User> => {
            const response = await sendMessageToMessageBox('UserByEmail', { email });

            return response.data;
        },
        workingTimeList: async (projectId: string): Promise<WorkingTime[]> => {
            const response = await sendMessageToMessageBox('WorkingTimeList', { projectId });

            return response.data.map((workingTime: any) => ({
                ...workingTime,
                start: new Date(workingTime.start),
                end: new Date(workingTime.end),
            }));
        },
        allWorkingTimeList: async (): Promise<WorkingTime[]> => {
            const response = await sendMessageToMessageBox('AllWorkingTimeList');

            return response.data.map((workingTime: any) => ({
                ...workingTime,
                start: new Date(workingTime.start),
                end: new Date(workingTime.end),
            }));
        },
        requestDateRangeExport: async (projectId: string): Promise<string> => {
            const response = await sendMessageToMessageBox('RequestDateRangeExport', { projectId });
            return response.data;
        },
    },
};

export default Api;
