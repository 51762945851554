import {Fab, Menu, MenuItem, Theme} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CreateNewCustomerDialog from './CreateNewCustomerDialog';
import React, {useState} from 'react';
import CreateNewProjectDialog from './CreateNewProjectDialog';

const styles = {
    root: (theme: Theme) => ({
        position: 'fixed',
        bottom: theme.spacing(1),
        right: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
            bottom: theme.spacing(2),
            right: theme.spacing(2),
        },
        [theme.breakpoints.up('md')]: {
            bottom: theme.spacing(3),
            right: theme.spacing(3),
        },
    }),
};

const NewFloatingActionButton = () => {
    const [newMenuAnchor, setNewMenuAnchor] = useState<any>(null); // @todo add type
    const [createNewCustomerDialogOpen, setCreateNewCustomerDialogOpen] = useState<boolean>(false);
    const [createNewProjectDialogOpen, setCreateNewProjectDialogOpen] = useState<boolean>(false);

    const openMenu = (event: React.MouseEvent) => {
        setNewMenuAnchor(event.currentTarget);
    };

    const closeNewMenu = () => {
        setNewMenuAnchor(null);
    };

    const openCreateNewCustomerDialog = () => {
        setCreateNewCustomerDialogOpen(true);
        closeNewMenu();
    };

    const openCreateNewProjectDialog = () => {
        setCreateNewProjectDialogOpen(true);
        closeNewMenu();
    };

    return (<>
        <Fab
            color={'secondary'}
            variant={'extended'}
            children={<><AddIcon /> &nbsp;Neu&nbsp;</>}
            sx={styles.root}
            onClick={openMenu}
        />
        <Menu open={Boolean(newMenuAnchor)} anchorEl={newMenuAnchor} onClose={closeNewMenu} keepMounted={true}>
            <MenuItem onClick={openCreateNewCustomerDialog}>Neuer Kunde</MenuItem>
            <MenuItem onClick={openCreateNewProjectDialog}>Neues Projekt</MenuItem>
        </Menu>
        <CreateNewCustomerDialog
            open={createNewCustomerDialogOpen}
            onClose={() => setCreateNewCustomerDialogOpen(false)}
        />
        <CreateNewProjectDialog
            open={createNewProjectDialogOpen}
            onClose={() => setCreateNewProjectDialogOpen(false)}
        />
    </>);
};

export default NewFloatingActionButton;
