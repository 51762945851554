import Command from '../../actions/command';
import {call, fork, put, select, take} from 'redux-saga/effects';
import {Action} from 'redux-actions';
import Event from '../../actions/event';
import Api from '../../../api';
import {RecordWorkingTimePayload} from '../../actions/workingTimeCommand';
import {v4 as uuidv4} from 'uuid';
import {Selector} from '../../selector';

const onRecordWorkingTime = function*(data: RecordWorkingTimePayload) {
    const workingTimeId = uuidv4();
    const userId: string = yield select(Selector.User.userId);

    try {
        yield call(Api.Command.WorkingTime.recordWorkingTime, {
            workingTimeId,
            userId,
            ...data,
        });

        yield put(Event.WorkingTime.workingTimeRecorded({
            workingTimeId,
            userId,
            ...data,
        }));

        yield put(Event.Customer.workingTimeRecorded({
            projectId: data.projectId,
            start: data.start,
            end: data.end,
        }));

        yield put(Command.Notification.enqueueNotification({
            severity: 'success',
            message: 'Die Arbeitszeit wurde gespeichert.',
        }));
    } catch (e) {
        yield put(Command.Notification.enqueueNotification({
            severity: 'error',
            message: 'Die Arbeitszeit konnte nicht gespeichert werden.',
        }));
    }
};

export function* recordWorkingTimeFlow() {
    while (true) {
        const action: Action<RecordWorkingTimePayload> = yield take(Command.WorkingTime.recordWorkingTime.toString());
        yield fork(onRecordWorkingTime, action.payload);
    }
}
