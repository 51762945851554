import {createAction} from 'redux-actions';

const createWorkingTimeCommand = <T>(type: string) => createAction<T>('_C/WT/' + type);

export interface FetchWorkingTimeListPayload {
    projectId: string;
}

export interface FetchAllWorkingTimeListPayload {
}

export interface RecordWorkingTimePayload {
    projectId: string;
    start: Date;
    end: Date;
    note: string;
    tags: string[];
}

export interface AdjustWorkingTimePayload {
    workingTimeId: string;
    projectId: string;
    start: Date;
    end: Date;
    oldStart: Date;
    oldEnd: Date;
    note: string;
    tags: string[];
}

const WorkingTimeCommand = {
    fetchWorkingTimeList: createWorkingTimeCommand<FetchWorkingTimeListPayload>('FETCH_WORKING_TIME_LIST'),
    fetchAllWorkingTimeList: createWorkingTimeCommand<FetchAllWorkingTimeListPayload>('FETCH_ALL_WORKING_TIME_LIST'),
    recordWorkingTime: createWorkingTimeCommand<RecordWorkingTimePayload>('RECORD_WORKING_TIME'),
    adjustWorkingTime: createWorkingTimeCommand<AdjustWorkingTimePayload>('ADJUST_WORKING_TIME'),
};

export default WorkingTimeCommand;
