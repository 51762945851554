import {
    Box,
} from '@mui/material';
import NewFloatingActionButton from './customerList/NewFloatingActionButton';
import TrackTimeDialog from './customerList/TrackTimeDialog';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Selector} from '../../state/selector';
import AssignUserToProjectDialog from './customerList/AssignUserToProjectDialog';
import {DateTime} from 'luxon';
import {Customer} from '../../state/types/Customer';
import WorkingTimeCustomerFilterBar from './customerList/WorkingTimeCustomerFilterBar';
import CustomerListTable from './customerList/CustomerListTable';
import ArchiveProjectDialog from './customerList/ArchiveProjectDialog';

const CustomerListPage = () => {
    const isAdmin = useSelector(Selector.User.isAdmin);
    const customerList = useSelector(Selector.Customer.customerList);
    const [projectToTrackTimeFor, setProjectToTrackTimeFor] = useState<string>();
    const [trackTimeDialogOpen, setTrackTimeDialogOpen] = useState<boolean>(false);
    const [projectToAssignUserTo, setProjectToAssignUserTo] = useState<string>();
    const [assignUserToProjectDialogOpen, setAssignUserToProjectDialogOpen] = useState<boolean>(false);
    const [projectToArchive, setProjectToArchive] = useState<string>();
    const [archiveProjectDialogOpen, setArchiveProjectDialogOpen] = useState<boolean>(false);
    const allWorkingTimeList = useSelector(Selector.WorkingTime.workingTimeLists);
    const [selectedCustomer, setSelectedCustomer] = useState<string>('');
    const [start, setStart] = useState<DateTime|null>(DateTime.now().minus({ day: 30 }).startOf('day'));
    const [end, setEnd] = useState<DateTime|null>(DateTime.now().startOf('minute'));
    const [filteredCustomerList, setFilteredCustomerList] = useState<Customer[]>([]);
    const [filterIsSetFlag, setFilterIsSetFlag] = useState<boolean>(false);
    const [showArchivedFlag, setShowArchivedFlag] = useState<boolean>(false);

    useEffect(() => {
        filterCustomerList(!filterIsSetFlag);
    }, [customerList, showArchivedFlag, allWorkingTimeList]);

    const openTrackTimeDialog = (projectId: string) => {
        setProjectToTrackTimeFor(projectId);
        setTrackTimeDialogOpen(true);
    };

    const closeTrackTimeDialog = () => {
        setTrackTimeDialogOpen(false);
        setProjectToTrackTimeFor(undefined);
    };

    const openAssignUserToProjectDialog = (projectId: string) => {
        setProjectToAssignUserTo(projectId);
        setAssignUserToProjectDialogOpen(true);
    };

    const closeAssignUserToProjectDialog = () => {
        setAssignUserToProjectDialogOpen(false);
        setProjectToAssignUserTo(undefined);
    };

    const openArchiveProjectDialog = (projectId: string) => {
        setProjectToArchive(projectId);
        setArchiveProjectDialogOpen(true);
    };

    const closeArchiveProjectDialog = () => {
        setArchiveProjectDialogOpen(false);
        setProjectToArchive(undefined);
    };

    const resetFilterCustomerPage = () => {
        setSelectedCustomer('');
        setStart(DateTime.now().minus({ day: 30 }).startOf('day'));
        setEnd(DateTime.now().startOf('minute'));
        filterCustomerList(true);
        setFilterIsSetFlag(false);
    };
    const filterCustomerPage = () => {
        filterCustomerList(false);
        setFilterIsSetFlag(true);
    };

    const filterCustomerList = (reset: boolean) => {
        const toFilterList = JSON.parse(JSON.stringify(customerList));
        const filteredCustomerList = [];

        for (const customer of toFilterList) {
            if (selectedCustomer === customer.customerId || selectedCustomer === '') {
                filteredCustomerList.push(customer);
            }

            for (const customer of filteredCustomerList) {
                const toFilterProjects = JSON.parse(JSON.stringify(customer.projects));
                customer.projects = [];

                for (const project of toFilterProjects) {
                    if ((projectHasWorkTimeBetween(project.projectId) || reset) &&
                        (showArchivedFlag === project.archived ||
                            (project.archived === undefined && !showArchivedFlag))) {
                        project.filterTime = reset ? project.total : filteredProjectTime(project.projectId);
                        customer.projects.push(project);
                    }
                }
            }
        }
        setFilteredCustomerList(filteredCustomerList);
    };

    const filteredProjectTime = (projectId: string): number => {
        if (!start || !end || !allWorkingTimeList[projectId]) {
            return 0;
        }
        let filteredTime = 0;
        for (const workTime of allWorkingTimeList[projectId]) {
            if (start!.toJSDate() <= workTime.start && workTime.start <= end!.toJSDate()) {
                const timeDiff = ((workTime.end.getTime() - workTime.start.getTime()) / (60 * 60 * 1000));
                filteredTime += parseFloat(timeDiff.toFixed(2));
            }
        }
        return filteredTime;
    };

    const projectHasWorkTimeBetween = (projectId: string): boolean => {
        if (!allWorkingTimeList[projectId]) {
            return false; 
        }

        for (const workTime of allWorkingTimeList[projectId]) {
            if (start!.toJSDate() <= workTime.start && workTime.start <= end!.toJSDate()) {
                return true;
            }
        }
        return false;
    };

    return (
        <Box>
            <WorkingTimeCustomerFilterBar
                start={start}
                end={end}
                onChangeStart={setStart}
                onChangeEnd={setEnd}
                selectedCustomer={selectedCustomer}
                onChangeCustomer={setSelectedCustomer}
                customerList={customerList}
                onClickFilterList={filterCustomerPage}
                onClickResetFilter={resetFilterCustomerPage}
                filterIsSet={filterIsSetFlag}
                showArchived={showArchivedFlag}
                onClickShowArchived={setShowArchivedFlag}
            />
            <CustomerListTable
                customerList={filteredCustomerList}
                onClickAssignUserToProjectDialog={openAssignUserToProjectDialog}
                onClickTrackTimeDialog={openTrackTimeDialog}
                onClickArchiveProjectDialog={openArchiveProjectDialog}
            />
            <TrackTimeDialog
                open={trackTimeDialogOpen}
                onClose={closeTrackTimeDialog}
                projectId={projectToTrackTimeFor}
            />
            <AssignUserToProjectDialog
                open={assignUserToProjectDialogOpen}
                onClose={closeAssignUserToProjectDialog}
                projectId={projectToAssignUserTo}
            />
            <ArchiveProjectDialog
                open={archiveProjectDialogOpen}
                onClose={closeArchiveProjectDialog}
                projectId={projectToArchive}
            />

            {isAdmin && <NewFloatingActionButton />}
        </Box>
    );
};

export default CustomerListPage;
