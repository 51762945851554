import {IconButton, ListItem, ListItemAvatar, ListItemText} from '@mui/material';
import UserAvatar from '../../common/UserAvatar';
import {formatDateTime} from '../../../util/formatDate';
import {WorkingTime} from '../../../state/types/WorkingTime';
import {DateTime, Interval} from 'luxon';
import EditIcon from '@mui/icons-material/Edit';
import {useSelector} from 'react-redux';
import {Selector} from '../../../state/selector';

interface WorkingTimeListItemProps {
    workingTime: WorkingTime;
    onEdit: () => void;
    archived: boolean;
}

const WorkingTimeListItem = (props: WorkingTimeListItemProps) => {
    const isAdmin = useSelector(Selector.User.isAdmin);
    const userId = useSelector(Selector.User.userId);
    const start = DateTime.fromJSDate(props.workingTime.start);
    const end = DateTime.fromJSDate(props.workingTime.end);
    const duration = Interval.fromDateTimes(start, end).length('hours').toFixed(2);

    return (
        <ListItem
            divider={true}
            secondaryAction={(
                <IconButton
                    disabled={(!isAdmin && props.workingTime.employeeId !== userId) || props.archived}
                    onClick={props.onEdit}
                >
                    <EditIcon />
                </IconButton>
            )}
        >
            <ListItemAvatar>
                <UserAvatar userId={props.workingTime.employeeId} />
            </ListItemAvatar>
            <ListItemText
                primary={
                    formatDateTime(start) + ' - ' + formatDateTime(end) + ' - Dauer: ' + duration + 'h'
                }
                secondary={props.workingTime.note}
            />
        </ListItem>
    );
};

export default WorkingTimeListItem;
