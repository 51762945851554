import Command from '../../actions/command';
import {call, fork, put, take} from 'redux-saga/effects';
import Event from '../../actions/event';
import Api from '../../../api';
import {Action} from 'redux-actions';
import {FetchWorkingTimeListPayload} from '../../actions/workingTimeCommand';
import {WorkingTime} from '../../types/WorkingTime';

const onFetchWorkingTimeList = function*(data: FetchWorkingTimeListPayload) {
    try {
        const workingTimeList: WorkingTime[] = yield call(Api.Query.workingTimeList, data.projectId);

        yield put(Event.WorkingTime.workingTimeListFetched({
            projectId: data.projectId,
            workingTimeList,
        }));

        // @todo load users here as well
    } catch (e) {
        yield put(Command.Notification.enqueueNotification({
            severity: 'error',
            message: 'Die List der Arbeitszeiten konnte nicht geladen werden.',
        }));
    }
};

export function* fetchWorkingTimeListFlow() {
    while (true) {
        const action: Action<FetchWorkingTimeListPayload> = yield take(
            Command.WorkingTime.fetchWorkingTimeList.toString(),
        );

        yield fork(onFetchWorkingTimeList, action.payload);
    }
}
