import {
    Box,
    Button, Card, CardActions, CardContent, CardHeader,
    Grid, IconButton, Tooltip,
} from '@mui/material';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import {Project} from '../../../state/types/Customer';
import SettingsIcon from '@mui/icons-material/Settings';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import UserAvatar from '../../common/UserAvatar';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import {useNavigate} from 'react-router';
import {useSelector} from 'react-redux';
import {Selector} from '../../../state/selector';

interface ProjectListItemProps {
    project: Project;
    customerId: string;

    onClickAssignUserToProjectDialog: (projectId: string) => void;
    onClickTrackTimeDialog: (projectId: string) => void;
    onClickArchiveProjectDialog: (projectId: string) => void;
}

const ProjectListItem = (props: ProjectListItemProps) => {
    const isAdmin = useSelector(Selector.User.isAdmin);
    const navigate = useNavigate();

    const showProjectDetails = (customerId: string, projectId: string) => {
        navigate(`/customers/${customerId}/projects/${projectId}`);
    };

    const backgroundColor = props.project.archived ? 'lightgray' : 'white';

    return (
        <>
            <Grid key={props.project.projectId} item={true} xl={3} lg={4} md={6} sm={9} xs={12}>
                <Card variant={'outlined'} sx={{marginTop: 1}} style={{background: backgroundColor}}>
                    <CardHeader
                        title={props.project.name}
                        subheader={(
                            <IconButton
                                sx={{position: 'absolute', right: '8px', top: '8px'}}
                                children={<SettingsIcon />}
                                onClick={() => {
                                    if (!isAdmin) {
                                        return;
                                    }
                                    props.onClickArchiveProjectDialog(props.project.projectId);
                                }}
                            />
                        )}
                        sx={{position: 'relative'}}
                    />
                    <CardContent>
                        <Box>
                            <Tooltip
                                title={'In der Zeitspanne des Filters  |  Innerhalb der letzten 5 Tage'}
                                placement={'top-start'}
                            >
                                <span style={{verticalAlign: 'middle'}}>
                                    <AccessTimeIcon sx={{verticalAlign: 'middle'}} />
                                    &nbsp;
                                    Gesamt:&nbsp;
                                    <strong>{props.project.filterTime ?
                                        props.project.filterTime!.toFixed(2) : 0
                                    }&nbsp;h</strong>
                                    &nbsp; |&nbsp;
                                    Kürzlich:&nbsp;
                                    <strong>{props.project.last30Days!.toFixed(2)}&nbsp;h</strong>
                                </span>
                            </Tooltip>
                        </Box>
                        <Box sx={{display: 'flex', flexDirection: 'row', marginTop: 1}}>
                            {props.project.employees.map(userId => <UserAvatar userId={userId} />)}
                            <IconButton
                                sx={{border: '1px solid #bdbdbd', padding: '7px'}}
                                onClick={
                                    () => props.onClickAssignUserToProjectDialog(props.project.projectId)
                                }
                                disabled={props.project.archived}
                            >
                                <AddIcon />
                            </IconButton>
                        </Box>
                    </CardContent>
                    <CardActions>
                        <Button
                            variant={'contained'}
                            startIcon={<MoreTimeIcon />}
                            children={'Zeit erfassen'}
                            onClick={() => props.onClickTrackTimeDialog(props.project.projectId)}
                            disabled={props.project.archived}
                        />
                        <Button
                            startIcon={<SearchIcon />}
                            onClick={() => showProjectDetails(props.customerId, props.project.projectId)}
                            children={'Details'}
                        />
                    </CardActions>
                </Card>
            </Grid>
        </>
    );
};

export default ProjectListItem;
