import {Box, Divider, Drawer, IconButton, List, Tooltip, Typography} from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import LogoutIcon from '@mui/icons-material/Logout';
import React from 'react';
import SideBarItem from './SideBarItem';
import {useSelector} from 'react-redux';
import {Selector} from '../state/selector';
import Keycloak from '../keycloak';
import Config from '../util/config';

const styles = {
    drawer: (theme: any) => ({
        '& .MuiDrawer-paper': {
            width: 280,
            [theme.breakpoints.up('lg')]: {
                paddingTop: '64px',
            },
            boxSizing: 'border-box',
        },
    }),
    container: {
        padding: 1.5,
        boxSizing: 'border-box',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    logoutContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginBottom: 1.5,
    },
    item: {
        display: 'flex',
        paddingTop: 1,
        paddingBottom: 1,
        margin: '0 12px',
        width: 'calc(100% - 24px)',
    },
    icon: {
        width: '24px',
        height: '24px',
        display: 'flex',
        alignItems: 'center',
        marginRight: 2,
        marginLeft: 1,
    },
    list: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    appInfoContainer: {
        paddingTop: 2,
    },
    appInfo: {
        margin: '0 auto',
        textAlign: 'center',
        fontSize: 10,
    },
};

interface SideBarProps {
    open: boolean;
    isLargeScreen: boolean;
    onClose: () => void;
}

const SideBar = (props: SideBarProps) => {
    const isAdmin = useSelector(Selector.User.isAdmin);

    return (
        <Drawer
            sx={styles.drawer}
            open={props.open || props.isLargeScreen}
            onClose={props.onClose}
            variant={props.isLargeScreen ? 'persistent' : 'temporary'}
            children={(
                <Box sx={styles.container}>
                    {!props.isLargeScreen && (<>
                        <Box sx={styles.logoutContainer}>
                            <Tooltip title={'Logout'}>
                                <IconButton color={'primary'} onClick={Keycloak.logout}>
                                    <LogoutIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <Divider />
                    </>)}
                    <List disablePadding={true} sx={styles.list}>
                        <Box>
                            {isAdmin && (
                                <SideBarItem url={'/dashboard'} label={'Dashboard'} icon={<DashboardIcon />} />
                            )}
                            <SideBarItem url={'/customers'} label={'Customers'} icon={<MoreTimeIcon />} />
                        </Box>
                        {isAdmin && (
                            <Box sx={styles.appInfoContainer}>
                                <Typography variant={'body2'} sx={styles.appInfo}>
                                    {Config.app.name} @ v{Config.app.version}<br />({Config.environment})
                                </Typography>
                            </Box>
                        )}
                    </List>
                </Box>
            )}
        />
    );
};

export default SideBar;
