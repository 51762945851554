import initializeKeycloak, {KeycloakInstance} from 'keycloak-js';
import Config from './util/config';

const keycloak: KeycloakInstance = initializeKeycloak({
    url: Config.keycloak.baseUrl,
    realm: Config.keycloak.realm,
    clientId: Config.keycloak.clientId,
});

const Keycloak = {
    initialize: (): Promise<void> => new Promise((resolve, reject) => {
        keycloak.init({
            onLoad: 'login-required',
            silentCheckSsoFallback: false,
            checkLoginIframe: false,
        })
            .then(authenticated => authenticated ? resolve() : reject())
            .catch(reason => reject(reason));
    }),
    refreshToken: (minValidity: number): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            keycloak.updateToken(minValidity)
                .then(() => resolve())
                .catch(() => reject());
        });
    },
    logout: () => keycloak.logout(),
    instance: keycloak,
};

export default Keycloak;
