import {Action, handleActions} from 'redux-actions';
import Event from '../actions/event';
import {WorkingTime} from '../types/WorkingTime';
import {
    AllWorkingTimeListFetchedPayload,
    WorkingTimeAdjustedPayload,
    WorkingTimeListFetchedPayload,
    WorkingTimeRecordedPayload,
} from '../actions/workingTimeEvent';

export interface WorkingTimeState {
    workingTimeLists: Record<string, WorkingTime[]>;
}

export const workingTimeInitialState: WorkingTimeState = {
    workingTimeLists: {},
};

/* eslint-disable max-len */
export const workingTimeReducer = handleActions<WorkingTimeState, any>(
    {
        [Event.WorkingTime.workingTimeListFetched.toString()]: (state, action: Action<WorkingTimeListFetchedPayload>): WorkingTimeState => {
            return {
                ...state,
                workingTimeLists: {
                    ...state.workingTimeLists,
                    [action.payload.projectId]: action.payload.workingTimeList,
                },
            };
        },
        [Event.WorkingTime.allWorkingTimeListFetched.toString()]: (state, action: Action<AllWorkingTimeListFetchedPayload>): WorkingTimeState => {
            const workingTimeRecord: Record<string, WorkingTime[]> = {};
            action.payload.allWorkingTimeList.forEach((workingTime: WorkingTime) => {
                if (!workingTimeRecord[workingTime.projectId]) {
                    workingTimeRecord[workingTime.projectId] = [];
                }
                workingTimeRecord[workingTime.projectId].push(workingTime);
            });

            return {
                ...state,
                workingTimeLists: workingTimeRecord,
            };
        },
        [Event.WorkingTime.workingTimeRecorded.toString()]: (state, action: Action<WorkingTimeRecordedPayload>): WorkingTimeState => {
            return {
                ...state,
                workingTimeLists: {
                    ...state.workingTimeLists,
                    [action.payload.projectId]: [
                        ...state.workingTimeLists[action.payload.projectId],
                        {
                            workingTimeId: action.payload.workingTimeId,
                            projectId: action.payload.projectId,
                            employeeId: action.payload.userId,
                            start: action.payload.start,
                            end: action.payload.end,
                            note: action.payload.note,
                            tags: action.payload.tags,
                        },
                    ],
                },
            };
        },
        [Event.WorkingTime.workingTimeAdjusted.toString()]: (state, action: Action<WorkingTimeAdjustedPayload>): WorkingTimeState => {
            return {
                ...state,
                workingTimeLists: {
                    ...state.workingTimeLists,
                    [action.payload.projectId]: state.workingTimeLists[action.payload.projectId].map(workingTime => {
                        if (workingTime.workingTimeId !== action.payload.workingTimeId) {
                            return workingTime;
                        }

                        return {
                            ...workingTime,
                            start: action.payload.start,
                            end: action.payload.end,
                            note: action.payload.note,
                            tags: action.payload.tags,
                        };
                    }),
                },
            };
        },
    },
    workingTimeInitialState,
);
