import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Typography,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import {useDispatch, useSelector} from 'react-redux';
import Command from '../../../state/actions/command';
import {Selector} from '../../../state/selector';

interface ArchiveProjectDialogProps {
    open: boolean;
    onClose: () => void;
    projectId?: string;
}

const ArchiveProjectDialog = (props: ArchiveProjectDialogProps) => {
    const dispatch = useDispatch();
    const project = useSelector((state: any) => Selector.Customer.project(state, props.projectId || ''));

    if (!project) {
        return null;
    }
    const changeArchiveStatus = () => {
        const projectId = project.projectId;
        isArchived ?
            dispatch(Command.Customer.unarchiveProject({projectId})) :
            dispatch(Command.Customer.archiveProject({projectId}));
        props.onClose();
    };

    const isArchived = project.archived;
    const content = isArchived ?
        `Sind Sie sicher, dass Sie die Archivierung vom Projekt "${project.name}" aufheben wollen?` :
        `Sind Sie sicher, dass Sie das Projekt "${project.name}" archivieren wollen?`;
    const title = isArchived ? 'Archivierung aufheben' : 'Projekt archivieren';

    return (
        <Dialog open={props.open} fullWidth={true} onClose={props.onClose}>
            <DialogTitle>
                {title}
            </DialogTitle>
            <DialogContent>
                <Typography> {content} </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>
                    Abbrechen
                </Button>
                <Button
                    variant={'contained'}
                    startIcon={<SaveIcon />}
                    onClick={changeArchiveStatus}
                >
                    {isArchived ? 'Archivierung aufheben!' : 'Projekt Archivieren!'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ArchiveProjectDialog;
