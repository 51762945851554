import {Action, handleActions} from 'redux-actions';
import Event from '../actions/event';
import {UserAuthenticatedPayload, UserFetchedPayload} from '../actions/userEvent';
import {User} from '../types/User';

export interface UserState {
    userId: string;
    roles: string[];
    users: Record<string, User>;
}

export const userInitialState: UserState = {
    userId: '',
    roles: [],
    users: {},
};

/* eslint-disable max-len */
export const userReducer = handleActions<UserState, any>(
    {
        [Event.User.userAuthenticated.toString()]: (state, action: Action<UserAuthenticatedPayload>): UserState => {
            return {
                ...state,
                userId: action.payload.userId,
                roles: action.payload.roles,
            };
        },
        [Event.User.userFetched.toString()]: (state, action: Action<UserFetchedPayload>): UserState => {
            return {
                ...state,
                users: {
                    ...state.users,
                    [action.payload.user.id]: action.payload.user,
                },
            };
        },
    },
    userInitialState,
);
