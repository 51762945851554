import {createAction} from 'redux-actions';

const createCustomerCommand = <T>(type: string) => createAction<T>('_C/CU/' + type);

export interface FetchCustomerListPayload {
}

export interface CreateNewCustomerPayload {
    name: string;
}

export interface CreateNewProjectPayload {
    customerId: string;
    name: string;
}

export interface AssignUserToProjectPayload {
    projectId: string;
    email: string;
}

export interface ArchiveProjectPayload {
    projectId: string;
}

export interface UnarchiveProjectPayload {
    projectId: string;
}

const CustomerCommand = {
    fetchCustomerList: createCustomerCommand<FetchCustomerListPayload>('FETCH_CUSTOMER_LIST'),
    createNewCustomer: createCustomerCommand<CreateNewCustomerPayload>('CREATE_NEW_CUSTOMER'),
    createNewProject: createCustomerCommand<CreateNewProjectPayload>('CREATE_NEW_PROJECT'),
    assignUserToProject: createCustomerCommand<AssignUserToProjectPayload>('ASSIGN_USER_TO_PROJECT'),
    archiveProject: createCustomerCommand<ArchiveProjectPayload>('ARCHIVE_PROJECT'),
    unarchiveProject: createCustomerCommand<UnarchiveProjectPayload>('UNARCHIVE_PROJECT'),
};

export default CustomerCommand;
