import UserSelector from './selectors/userSelector';
import NotificationSelector from './selectors/notificationSelector';
import CustomerSelector from './selectors/customerSelector';
import WorkingTimeSelector from './selectors/workingTimeSelector';

export const Selector = {
    User: UserSelector,
    Notification: NotificationSelector,
    Customer: CustomerSelector,
    WorkingTime: WorkingTimeSelector,
};
