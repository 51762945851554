import CustomerCommand from './customerCommand';
import NotificationCommand from './notificationCommand';
import UserCommand from './userCommand';
import WorkingTimeCommand from './workingTimeCommand';
import DateRangeExportCommand from './DateRangeExportCommand';

const Command = {
    User: UserCommand,
    Customer: CustomerCommand,
    WorkingTime: WorkingTimeCommand,
    Notification: NotificationCommand,
    DateRangeExport: DateRangeExportCommand,
};

export default Command;
