import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Theme,
    Typography,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import {useEffect, useState} from 'react';
import {DateTime, Interval} from 'luxon';
import {useDispatch} from 'react-redux';
import Command from '../../../state/actions/command';
import TagBar from './trackTimeDialog/TagBar';

const MAX_DAY_DIFFERENCE = 60;
const MAX_MINUTES_DIFFERENCE = 30;

const styles = {
    startEndWrapper: (theme: Theme) => ({
        marginTop: 1,
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            alignItems: 'center',
        },
    }),
    endWrapper: (theme: Theme) => ({
        [theme.breakpoints.down('md')]: {
            marginTop: 1,
        },
        [theme.breakpoints.up('md')]: {
            marginLeft: 1,
        },
    }),
};

interface TrackTimeDialogProps {
    open: boolean;
    onClose: () => void;
    projectId?: string;
}

const TrackTimeDialog = (props: TrackTimeDialogProps) => {
    const dispatch = useDispatch();
    const [start, setStart] = useState<Date|null>();
    const [end, setEnd] = useState<Date|null>();
    const [tags, setTags] = useState<string[]>([]);
    const [description, setDescription] = useState<string>('');
    const [validTime, setValidTime] = useState<boolean>(true);
    const timeZoneDiff = new Date().getTimezoneOffset();
    const min = DateTime.now().minus({ days: MAX_DAY_DIFFERENCE + 1 }).toJSDate();
    const max = DateTime.now().plus({ minutes: MAX_MINUTES_DIFFERENCE }).toJSDate();

    useEffect(() => {
        setStart(DateTime.now().minus({ hour: 2 }).startOf('hour').toJSDate());
        setEnd(DateTime.now().startOf('hour').toJSDate());
        setTags([]);
        setDescription('');
    }, [props.open]);

    useEffect(() => {
        setValidTime(start && end ?
            start < end && start > min && end < max : false);
    }, [start, end]);

    const trackTime = () => {
        if (!start || !end) {
            return;
        }

        dispatch(Command.WorkingTime.recordWorkingTime({
            projectId: props.projectId!,
            start: start,
            end: end,
            note: description,
            tags,
        }));

        props.onClose();
    };

    const timeDiff = validTime && start && end ?
        Interval.fromDateTimes(start, end).length('hours').toFixed(2) + 'Stunden' :
        <span style={{color: 'red'}}> Ungültige Zeitspanne! </span>;

    const defaultDateString = (date: Date | null) => {
        if (!date) {
            return '';
        }
        const diffDate = new Date(date);
        diffDate.setMinutes(diffDate.getMinutes() - timeZoneDiff);
        return (diffDate.toISOString().slice(0, 16));
    };

    return (
        <Dialog open={props.open} fullWidth={true} maxWidth={'md'}>
            <DialogTitle>Zeit erfassen</DialogTitle>
            <DialogContent>
                <Box sx={styles.startEndWrapper}>
                    {/* eslint-disable max-len */}
                    <Box>
                        <TextField
                            id={'datetime-local'}
                            label={'Start Zeit'}
                            type={'datetime-local'}
                            defaultValue={defaultDateString(DateTime.now()
                                .minus({ hour: 2 }).startOf('hour').toJSDate())}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                min: defaultDateString(DateTime.now().minus({ days: MAX_DAY_DIFFERENCE }).toJSDate()),
                                max: defaultDateString(DateTime.now().toJSDate()),
                            }}
                            onChange={(event: any) => {
                                setStart(new Date(event.target.value));
                            }}
                        />
                    </Box>

                    <Box sx={styles.endWrapper}>
                        <TextField
                            id={'datetime-local'}
                            label={'End Zeit'}
                            type={'datetime-local'}
                            defaultValue={defaultDateString(DateTime.now().startOf('hour').toJSDate())}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                min: defaultDateString(DateTime.now().minus({ days: MAX_DAY_DIFFERENCE }).toJSDate()),
                                max: defaultDateString(DateTime.now().plus({ minutes: MAX_MINUTES_DIFFERENCE }).toJSDate()),
                            }}
                            onChange={(event: any) => {
                                setEnd(new Date(event.target.value));
                            }}
                        />
                    </Box>
                    {/* eslint-enable max-len */}
                    <Box sx={{ marginLeft: 1 }}>
                        <Typography>
                            {timeDiff}
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ marginTop: 2 }}>
                    <TagBar tags={tags} onChange={setTags} />
                </Box>
                <Box sx={{ marginTop: 2 }}>
                    <TextField
                        label={'Beschreibung'}
                        multiline={true}
                        minRows={3}
                        maxRows={3}
                        fullWidth={true}
                        value={description}
                        onChange={event => setDescription(event.target.value)}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>
                    Abbrechen
                </Button>
                <Button variant={'contained'} startIcon={<SaveIcon />} onClick={trackTime} disabled={!validTime}>
                    Speichern
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default TrackTimeDialog;
