import Command from '../../actions/command';
import {call, fork, put, take} from 'redux-saga/effects';
import {Action} from 'redux-actions';
import Event from '../../actions/event';
import Api from '../../../api';
import {AssignUserToProjectPayload} from '../../actions/customerCommand';
import {User} from '../../types/User';

const onAssignUserToProject = function*(data: AssignUserToProjectPayload) {
    let userId: string;

    try {
        const user: User = yield call(Api.Query.userByEmail, data.email);
        userId = user.id;
    } catch (e) {
        yield put(Command.Notification.enqueueNotification({
            severity: 'error',
            message: 'Es konnte kein Nutzer mit dieser E-Mail Adresse gefunden werden.',
        }));
        return;
    }

    try {
        yield call(Api.Command.Project.assignEmployeeToProject, {
            projectId: data.projectId,
            userId,
        });

        yield put(Event.Customer.userAssignedToProject({
            projectId: data.projectId,
            userId,
        }));

        yield put(Command.User.fetchUser({ userId }));
    } catch (e) {
        yield put(Command.Notification.enqueueNotification({
            severity: 'error',
            message: 'Der Mitarbeiter konnte dem Projekt nicht hinzugefügt werden.',
        }));
    }
};

export function* assignUserToProjectFlow() {
    while (true) {
        const action: Action<AssignUserToProjectPayload> = yield take(Command.Customer.assignUserToProject.toString());
        yield fork(onAssignUserToProject, action.payload);
    }
}
