import {ReduxState} from '../reducer';
import {Customer, Project} from '../types/Customer';
import {createSelector} from 'reselect';

const customerList = (state: ReduxState): Customer[] => state.customer.customerList;

const customer = createSelector(
    [customerList, (_: any, customerId: string) => customerId],
    (customerList, customerId): Customer|null => {
        for (const customer of customerList) {
            if (customer.customerId === customerId) {
                return customer;
            }
        }

        return null;
    },
);

const project = createSelector(
    [customerList, (_: any, projectId: string) => projectId],
    (customerList, projectId): Project|null => {
        for (const customer of customerList) {
            for (const project of customer.projects) {
                if (project.projectId === projectId) {
                    return project;
                }
            }
        }

        return null;
    },
);

const CustomerSelector = {
    customerList,

    customer,
    project,
};

export default CustomerSelector;
