import {combineReducers} from 'redux';
import {UserState, userReducer} from './reducers/userReducer';
import {NotificationState, notificationReducer} from './reducers/notificationReducer';
import {CustomerState, customerReducer} from './reducers/customerReducer';
import {WorkingTimeState, workingTimeReducer} from './reducers/workingTimeReducer';

export interface ReduxState {
    user: UserState;
    notification: NotificationState;
    customer: CustomerState;
    workingTime: WorkingTimeState;
}

export const rootReducer = combineReducers({
    user: userReducer,
    notification: notificationReducer,
    customer: customerReducer,
    workingTime: workingTimeReducer,
});
