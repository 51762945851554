import {ReduxState} from '../reducer';
import {createSelector} from 'reselect';
import {WorkingTime} from '../types/WorkingTime';

const workingTimeLists = (state: ReduxState): Record<string, WorkingTime[]> => state.workingTime.workingTimeLists;

const workingTimeList = createSelector(
    [workingTimeLists, (_: any, projectId: string) => projectId],
    (workingTimeLists, projectId): WorkingTime[]|null => workingTimeLists[projectId] || null,
);

const WorkingTimeSelector = {
    workingTimeLists,

    workingTimeList,
};

export default WorkingTimeSelector;
