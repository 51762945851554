import {
    Box,
    Button, Card, IconButton,
    List, Tooltip,
    Typography,
} from '@mui/material';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import WorkingTimeListItem from './WorkingTimeListItem';
import {WorkingTime} from '../../../state/types/WorkingTime';
import SettingsIcon from '@mui/icons-material/Settings';
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';
import {useSelector} from 'react-redux';
import {Selector} from '../../../state/selector';
import {Customer, Project} from '../../../state/types/Customer';
import {useEffect} from 'react';

interface ProjectDetailTableProps {
    customer: Customer;
    project: Project;
    workingTimeList: WorkingTime[];
    onClickTrackTimeDialog: () => void;
    onClickEditWorkingTimeDialog: (workingTimeId: string) => void;
    onClickArchiveProjectDialog: () => void;
    onClickExportPdfForSelectedTime: () => void;
}

const ProjectDetailTable = (props: ProjectDetailTableProps) => {
    const isAdmin = useSelector(Selector.User.isAdmin);

    useEffect(() => {
    }, [props.project]);

    if (!props.customer || !props.project) {
        return null;
    }

    return (
        <>
            <Box>
                <Typography variant={'h1'}>
                    {props.customer.name} / {props.project.name}
                    <IconButton
                        children={<SettingsIcon />}
                        onClick={() => {
                            if (!isAdmin) {
                                return;
                            }
                            props.onClickArchiveProjectDialog();
                        }}
                    />
                </Typography>

            </Box>

            <Box>
                <Tooltip
                    title={'In der Zeitspanne des Filters  |  Innerhalb der letzten 5 Tage'}
                    placement={'bottom-start'}
                >
                    <span style={{verticalAlign: 'middle'}}>
                        <AccessTimeIcon sx={{verticalAlign: 'middle'}} />
                        &nbsp;
                        Gesamt:&nbsp;
                        <strong>
                            {props.project.filterTime ?
                                props.project.filterTime.toFixed(2) :
                                props.project.total.toFixed(2)
                            }&nbsp;h
                        </strong>
                        &nbsp; |&nbsp;
                        Kürzlich:&nbsp;
                        <strong>{props.project.last30Days.toFixed(2)}&nbsp;h</strong>
                    </span>
                </Tooltip>
                <Button
                    sx={{ marginLeft: 5 }}
                    variant={'contained'}
                    startIcon={<MoreTimeIcon />}
                    children={'Zeit erfassen'}
                    onClick={() => props.onClickTrackTimeDialog()}
                    disabled={props.project.archived}
                />
                {props.project.archived && <span style={{marginLeft: 30}}>[ Archiviert ] </span>}
                {isAdmin &&
                    <Button
                        sx={{ marginLeft: 5 }}
                        variant={'contained'}
                        startIcon={<GetAppRoundedIcon />}
                        children={'Exportieren'}
                        onClick={props.onClickExportPdfForSelectedTime}
                    />
                }
            </Box>

            {props.workingTimeList && (
                <Card sx={{ marginTop: 2 }}>
                    <List>
                        {props.workingTimeList.map(workingTime => (
                            <WorkingTimeListItem
                                key={workingTime.workingTimeId}
                                workingTime={workingTime}
                                onEdit={() => props.onClickEditWorkingTimeDialog(workingTime.workingTimeId)}
                                archived={props.project!.archived}
                            />
                        ))}
                    </List>
                </Card>
            )}
        </>
    );
};

export default ProjectDetailTable;
