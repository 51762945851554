import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Command from '../../../state/actions/command';
import {Selector} from '../../../state/selector';

interface CreateNewProjectDialogProps {
    open: boolean;
    onClose: () => void;
}

const CreateNewProjectDialog = (props: CreateNewProjectDialogProps) => {
    const dispatch = useDispatch();
    const customerList = useSelector(Selector.Customer.customerList);
    const [customer, setCustomer] = useState<string>('');
    const [name, setName] = useState<string>('');

    useEffect(() => {
        setCustomer('');
        setName('');
    }, [props.open]);

    const createNewProject = () => {
        dispatch(Command.Customer.createNewProject({
            customerId: customer,
            name,
        }));
        props.onClose();
    };

    return (
        <Dialog open={props.open} fullWidth={true} onClose={props.onClose}>
            <DialogTitle>Neues Projekt erstellen</DialogTitle>
            <DialogContent>
                <FormControl fullWidth={true} sx={{ marginTop: 1 }}>
                    <InputLabel id={'create-new-project-dialog-customer-select'}>Kunde</InputLabel>
                    <Select
                        labelId={'create-new-project-dialog-customer-select'}
                        label={'Kunde wählen'}
                        value={customer}
                        onChange={event => setCustomer(event.target.value)}
                    >
                        <MenuItem value={''} disabled={true}><em><strong>Kunde</strong></em></MenuItem>
                        {customerList.map(customer => (
                            <MenuItem value={customer.customerId}>{customer.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField
                    label={'Projektname'}
                    value={name}
                    onChange={event => setName(event.target.value)}
                    sx={{ marginTop: 2 }}
                    disabled={!customer}
                    fullWidth={true}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>
                    Abbrechen
                </Button>
                <Button variant={'contained'} startIcon={<SaveIcon />} onClick={createNewProject}>
                    Speichern
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CreateNewProjectDialog;
