import {createAction} from 'redux-actions';
import {Customer} from '../types/Customer';

const createCustomerEvent = <T>(type: string) => createAction<T>('_E/CU/' + type);

export interface CustomerListFetchedPayload {
    customerList: Customer[];
}

export interface NewCustomerCreatedPayload {
    customerId: string;
    name: string;
}

export interface NewProjectCreatedPayload {
    projectId: string;
    customerId: string;
    name: string;
}

export interface UserAssignedToProjectPayload {
    projectId: string;
    userId: string;
}

export interface ProjectArchivedPayload {
    projectId: string;
}

export interface ProjectUnarchivedPayload {
    projectId: string;
}

export interface WorkingTimeRecordedPayload {
    projectId: string;
    start: Date;
    end: Date;
}

export interface WorkingTimeAdjustedPayload {
    projectId: string;
    start: Date;
    end: Date;
    oldStart: Date;
    oldEnd: Date;
}

const CustomerEvent = {
    customerListFetched: createCustomerEvent<CustomerListFetchedPayload>('CUSTOMER_LIST_FETCHED'),
    newCustomerCreated: createCustomerEvent<NewCustomerCreatedPayload>('NEW_CUSTOMER_CREATED'),
    newProjectCreated: createCustomerEvent<NewProjectCreatedPayload>('NEW_PROJECT_CREATED'),
    userAssignedToProject: createCustomerEvent<UserAssignedToProjectPayload>('USER_ASSIGNED_TO_PROJECT'),
    projectArchived: createCustomerEvent<ProjectArchivedPayload>('PROJECT_ARCHIVED'),
    projectUnarchived: createCustomerEvent<ProjectUnarchivedPayload>('PROJECT_UNARCHIVED'),
    workingTimeRecorded: createCustomerEvent<WorkingTimeRecordedPayload>('WORKING_TIME_RECORDED'),
    workingTimeAdjusted: createCustomerEvent<WorkingTimeAdjustedPayload>('WORKING_TIME_ADJUSTED'),
};

export default CustomerEvent;
