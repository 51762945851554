import Command from '../../actions/command';
import {call, fork, put, take} from 'redux-saga/effects';
import Event from '../../actions/event';
import Api from '../../../api';
import {Customer} from '../../types/Customer';

const onFetchCustomerList = function*() {
    try {
        const customerList: Customer[] = yield call(Api.Query.customerList);

        yield put(Event.Customer.customerListFetched({ customerList }));

        const usersToLoad: string[] = [];
        customerList.forEach(customer => {
            customer.projects.forEach(project => {
                project.employees.forEach(userId => {
                    if (!usersToLoad.includes(userId)) {
                        usersToLoad.push(userId);
                    }
                });
            });
        });

        for (const userId of usersToLoad) {
            yield put(Command.User.fetchUser({ userId }));
        }
    } catch (e) {
        yield put(Command.Notification.enqueueNotification({
            severity: 'error',
            message: 'Die Liste der Kunden konnte nicht geladen werden.',
        }));
    }
};

export function* fetchCustomerListFlow() {
    while (true) {
        yield take(Command.Customer.fetchCustomerList.toString());
        yield fork(onFetchCustomerList);
    }
}
