import { all, call, delay, spawn } from 'redux-saga/effects';
import {createNewCustomerFlow} from './sagas/customer/createNewCustomerFlow';
import {enqueueNotificationFlow} from './sagas/notification/enqueueNotificationFlow';
import {fetchCustomerListFlow} from './sagas/customer/fetchCustomerListFlow';
import {createNewProjectFlow} from './sagas/customer/createNewProjectFlow';
import {assignUserToProjectFlow} from './sagas/customer/assignUserToProjectFlow';
import {fetchUserFlow} from './sagas/user/fetchUserFlow';
import {fetchWorkingTimeListFlow} from './sagas/workingTime/fetchWorkingTimeListFlow';
import {fetchAllWorkingTimeListFlow} from './sagas/workingTime/fetchAllWorkingTimeListFlow';
import {recordWorkingTimeFlow} from './sagas/workingTime/recordWorkingTimeFlow';
import {adjustWorkingTimeFlow} from './sagas/workingTime/adjustWorkingTimeFlow';
import {archiveProjectFlow} from './sagas/customer/archiveProjectFlow';
import {unarchiveProjectFlow} from './sagas/customer/unarchiveProjectFlow';
import {fetchRequestDateRangeExport} from './sagas/DateRangeExport/requestDateRangeExportFlow';

/**
 * Prevents the root saga from terminating entirely due to some error in another saga
 *
 * @param saga
 */
const makeRestartable = (saga: any) => {
    return function*() {
        yield spawn(function*() {
            while (true) {
                try {
                    yield call(saga);

                    /* eslint-disable-next-line no-console */
                    console.error(
                        'Unexpected root saga termination. The root sagas should live during the whole app lifetime!',
                        saga,
                    );
                } catch (e) {
                    /* eslint-disable-next-line no-console */
                    console.error('Saga error, the saga will be restarted', e);
                }
                yield delay(1000); // Workaround to avoid infinite error loops
            }
        });
    };
};

const sagas: any[] = [
    fetchCustomerListFlow,
    createNewCustomerFlow,
    createNewProjectFlow,
    assignUserToProjectFlow,
    archiveProjectFlow,
    unarchiveProjectFlow,

    enqueueNotificationFlow,

    fetchUserFlow,

    fetchWorkingTimeListFlow,
    fetchAllWorkingTimeListFlow,
    recordWorkingTimeFlow,
    adjustWorkingTimeFlow,

    fetchRequestDateRangeExport,
].map(makeRestartable);

export default function* rootSaga() {
    /* eslint-disable-next-line no-console */
    console.log('Root saga started');

    yield all(sagas.map((saga: any) => call(saga)));
};
