import {
    Box,
    Divider,
    Grid,
    Theme,
    Typography,
} from '@mui/material';
import {Customer} from '../../../state/types/Customer';
import ProjectListItem from './ProjectListItem';

interface CustomerListTableProps {
    customerList: Customer[];
    onClickAssignUserToProjectDialog: (projectId: string) => void;
    onClickTrackTimeDialog: (projectId: string) => void;
    onClickArchiveProjectDialog: (projectId: string) => void;
}

const styles = {
    headerText: (theme: Theme) => ({
        display: 'inline-block',
        margin: '0 auto',
        backgroundColor: '#F9FAFB',
        padding: '0 8px',
        [theme.breakpoints.up('md')]: {
            padding: '0 16px',
        },
    }),
    headerWrapper: (theme: Theme) => ({
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: '8px',
        paddingTop: '20px',
        [theme.breakpoints.up('md')]: {
            paddingTop: '28px',
        },
    }),
    headerDivider: (theme: Theme) => ({
        marginBottom: '-20px',
        [theme.breakpoints.up('md')]: {
            marginBottom: '-28px',
        },
    }),
};


const CustomerListTable = (props: CustomerListTableProps) => {
    return (
        <>
            {props.customerList.map(customer => (
                <Box key={customer.customerId} sx={{marginBottom: 4}}>
                    <Box sx={styles.headerWrapper}>
                        <Divider sx={styles.headerDivider} />
                        <Typography
                            variant={'h2'}
                            sx={styles.headerText}
                        >
                            {customer.name}
                        </Typography>
                    </Box>

                    <Grid container={true} spacing={2}>
                        {customer.projects.map(project => (
                            <ProjectListItem
                                project={project}
                                customerId={customer.customerId}
                                onClickAssignUserToProjectDialog={props.onClickAssignUserToProjectDialog}
                                onClickTrackTimeDialog={props.onClickTrackTimeDialog}
                                onClickArchiveProjectDialog={props.onClickArchiveProjectDialog}
                            />
                        ))}
                    </Grid>
                </Box>
            ))}
        </>
    );
};

export default CustomerListTable;
