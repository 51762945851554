import {createAction} from 'redux-actions';
import {WorkingTime} from '../types/WorkingTime';

const createWorkingTimeEvent = <T>(type: string) => createAction<T>('_E/WT/' + type);

export interface WorkingTimeListFetchedPayload {
    projectId: string;
    workingTimeList: WorkingTime[];
}

export interface AllWorkingTimeListFetchedPayload {
    allWorkingTimeList: WorkingTime[];
}

export interface WorkingTimeRecordedPayload {
    workingTimeId: string;
    projectId: string;
    userId: string;
    start: Date;
    end: Date;
    note: string;
    tags: string[];
}

export interface WorkingTimeAdjustedPayload {
    workingTimeId: string;
    projectId: string;
    start: Date;
    end: Date;
    note: string;
    tags: string[];
}

const WorkingTimeEvent = {
    workingTimeListFetched: createWorkingTimeEvent<WorkingTimeListFetchedPayload>('WORKING_TIME_LIST_FETCHED'),
    allWorkingTimeListFetched:
        createWorkingTimeEvent<AllWorkingTimeListFetchedPayload>('ALL_WORKING_TIME_LIST_FETCHED'),
    workingTimeRecorded: createWorkingTimeEvent<WorkingTimeRecordedPayload>('WORKING_TIME_RECORDED'),
    workingTimeAdjusted: createWorkingTimeEvent<WorkingTimeAdjustedPayload>('WORKING_TIME_ADJUSTED'),
};

export default WorkingTimeEvent;
