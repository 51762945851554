import Command from '../../actions/command';
import {call, fork, put, take} from 'redux-saga/effects';
import {Action} from 'redux-actions';
import Event from '../../actions/event';
import Api from '../../../api';
import {v4 as uuidv4} from 'uuid';
import {CreateNewProjectPayload} from '../../actions/customerCommand';

const onCreateNewProject = function*(data: CreateNewProjectPayload) {
    const projectId = uuidv4();

    try {
        yield call(Api.Command.Project.createNewProject, {
            projectId,
            ...data,
        });

        yield put(Event.Customer.newProjectCreated({
            projectId,
            ...data,
        }));
    } catch (e) {
        yield put(Command.Notification.enqueueNotification({
            severity: 'error',
            message: 'Das Projekt konnte nicht erstellt werden.',
        }));
    }
};

export function* createNewProjectFlow() {
    while (true) {
        const action: Action<CreateNewProjectPayload> = yield take(Command.Customer.createNewProject.toString());
        yield fork(onCreateNewProject, action.payload);
    }
}
