import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Keycloak from './keycloak';
import {store} from './state/store';
import Event from './state/actions/event';

(async () => {
    await Keycloak.initialize();

    const parsedToken = Keycloak.instance.tokenParsed!;
    const userId = parsedToken.sub || '';
    const roles = parsedToken.resource_access?.['ins-time-tracking-fe']?.roles || [];

    store.dispatch(Event.User.userAuthenticated({userId, roles}));

    const root = ReactDOM.createRoot(
        document.getElementById('root') as HTMLElement,
    );
    root.render(<App />);
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
