import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, TextField,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import Command from '../../../state/actions/command';

interface CreateNewCustomerDialogProps {
    open: boolean;
    onClose: () => void;
}

const CreateNewCustomerDialog = (props: CreateNewCustomerDialogProps) => {
    const dispatch = useDispatch();
    const [name, setName] = useState<string>('');

    useEffect(() => {
        setName('');
    }, [props.open]);

    const createNewCustomer = () => {
        dispatch(Command.Customer.createNewCustomer({ name }));
        props.onClose();
    };

    return (
        <Dialog open={props.open} fullWidth={true} onClose={props.onClose}>
            <DialogTitle>Neuen Kunden erstellen</DialogTitle>
            <DialogContent>
                <TextField
                    label={'Name'}
                    value={name}
                    onChange={event => setName(event.target.value)}
                    sx={{ marginTop: 1 }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>
                    Abbrechen
                </Button>
                <Button variant={'contained'} startIcon={<SaveIcon />} onClick={createNewCustomer}>
                    Speichern
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CreateNewCustomerDialog;
