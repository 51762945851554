import {createAction} from 'redux-actions';

const createDateRangeExportCommand = <T>(type: string) => createAction<T>('_C/WT/' + type);

export interface RequestDateRangeExportPayload {
    projectId: string;
    startParam: string;
    endParam: string;
}

const DateRangeExportCommand = {
    requestDateRangeExport:
        createDateRangeExportCommand<RequestDateRangeExportPayload>('REQUEST_DATE_RANGE_EXPORT'),
};

export default DateRangeExportCommand;
