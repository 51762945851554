import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Theme,
} from '@mui/material';
import {DateTime} from 'luxon';
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import {Customer} from '../../../state/types/Customer';
import {useSelector} from 'react-redux';
import {Selector} from '../../../state/selector';

interface WorkingTimeCustomerFilterBarProps {
    start: DateTime|null;
    end: DateTime|null;
    onChangeStart: (newValue: DateTime|null) => void;
    onChangeEnd: (newValue: DateTime|null) => void;

    selectedCustomer: string;
    onChangeCustomer: (newValue: string) => void;
    customerList: Customer[];

    onClickFilterList: () => void;
    onClickResetFilter: () => void;
    filterIsSet: boolean;

    showArchived: boolean;
    onClickShowArchived: (checkBoxValue: boolean) => void;
}

const styles = {
    startEndWrapper: (theme: Theme) => ({
        marginTop: 1,
        marginBottom: 5,
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            alignItems: 'center',
        },
    }),
    endWrapper: (theme: Theme) => ({
        [theme.breakpoints.down('md')]: {
            marginTop: 1,
        },
        [theme.breakpoints.up('md')]: {
            marginLeft: 1,
        },
    }),
    customerSelectorWrapper: (theme: Theme) => ({
        minWidth: '200px',
        [theme.breakpoints.down('md')]: {
            marginTop: 1,
        },
        [theme.breakpoints.up('md')]: {
            marginLeft: 1,
        },
    }),
    marginLeftWrapper: (theme: Theme) => ({
        [theme.breakpoints.down('md')]: {
            marginTop: 1,
        },
        [theme.breakpoints.up('md')]: {
            marginLeft: 1,
        },
    }),
};

const WorkingTimeCustomerFilterBar = (props: WorkingTimeCustomerFilterBarProps) => {
    const isAdmin = useSelector(Selector.User.isAdmin);

    return (
        <Box sx={styles.startEndWrapper}>
            <Box>
                <DesktopDatePicker
                    label={'Start'}
                    value={props.start}
                    onChange={props.onChangeStart}
                    renderInput={(params) => <TextField {...params} />}
                    maxDate={DateTime.now()}
                    inputFormat={'dd.MM.yyyy'}
                    mask={'__/__/____'}
                    views={['day']}
                />
            </Box>
            <Box sx={styles.endWrapper}>
                <DesktopDatePicker
                    label={'Ende'}
                    value={props.end}
                    onChange={props.onChangeEnd}
                    renderInput={(params) => <TextField {...params} />}
                    maxDate={DateTime.now()}
                    minDate={props.start!}
                    inputFormat={'dd.MM.yyyy'}
                    mask={'__/__/____'}
                    views={['day']}
                />
            </Box>
            <Box sx={styles.customerSelectorWrapper}>
                <FormControl fullWidth={true}>
                    <InputLabel id={'customer-page-customer-filter-select-label'}>Kunde</InputLabel>
                    <Select
                        labelId={'customer-page-customer-filter-select-label'}
                        id={'customer-page-customer-filter-select'}
                        value={props.selectedCustomer}
                        label={'Kunde'}
                        onChange={(event) => {
                            props.onChangeCustomer(event.target.value);
                        }}
                    >
                        {props.selectedCustomer !== '' &&
                            <MenuItem value={''}> -- Alle Kunden -- </MenuItem>
                        }
                        {props.customerList.map(customer => (
                            <MenuItem value={customer.customerId}>{customer.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            <Box sx={styles.marginLeftWrapper}>
                <Button
                    variant={'contained'}
                    startIcon={<MoreTimeIcon />}
                    children={'Filtern'}
                    onClick={props.onClickFilterList}
                />
            </Box>
            <Box sx={styles.marginLeftWrapper}>
                <Button
                    variant={'contained'}
                    startIcon={<MoreTimeIcon />}
                    children={'Zurücksetzen'}
                    onClick={props.onClickResetFilter}
                    disabled={!props.filterIsSet}
                />
            </Box>
            {isAdmin &&
                <Box sx={styles.marginLeftWrapper}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={props.showArchived}
                                onChange={(event) => {
                                    props.onClickShowArchived(event.target.checked);
                                }}
                            />
                        }
                        label={'Archivierte Projekte'}
                    />
                </Box>
            }
        </Box>
    );
};

export default WorkingTimeCustomerFilterBar;
