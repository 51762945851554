import React from 'react';
import {Provider} from 'react-redux';
import {store} from './state/store';
import {ThemeProvider} from '@mui/material';
import {BrowserRouter} from 'react-router-dom';
import {theme} from './mui/theme';
import Main from './Main';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterLuxon} from '@mui/x-date-pickers/AdapterLuxon';
import Notifier from './components/common/Notifier';

const App = () => {
    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <Notifier />
                    <BrowserRouter>
                        <Main />
                    </BrowserRouter>
                </LocalizationProvider>
            </ThemeProvider>
        </Provider>
    );
};

export default App;
