import Command from '../../actions/command';
import {call, fork, put, take} from 'redux-saga/effects';
import {Action} from 'redux-actions';
import Event from '../../actions/event';
import Api from '../../../api';
import {v4 as uuidv4} from 'uuid';
import {CreateNewCustomerPayload} from '../../actions/customerCommand';

const onCreateNewCustomer = function*(data: CreateNewCustomerPayload) {
    const customerId = uuidv4();

    try {
        yield call(Api.Command.Customer.createNewCustomer, {
            customerId,
            ...data,
        });

        yield put(Event.Customer.newCustomerCreated({
            customerId,
            ...data,
        }));
    } catch (e) {
        yield put(Command.Notification.enqueueNotification({
            severity: 'error',
            message: 'Der Kunde konnte nicht erstellt werden.',
        }));
    }
};

export function* createNewCustomerFlow() {
    while (true) {
        const action: Action<CreateNewCustomerPayload> = yield take(Command.Customer.createNewCustomer.toString());
        yield fork(onCreateNewCustomer, action.payload);
    }
}
