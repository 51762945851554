import {AppBar, Box, IconButton, Toolbar, Tooltip} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import React from 'react';
import Keycloak from '../keycloak';

const styles = {
    appBar: (theme: any) => ({
        [theme.breakpoints.up('lg')]: {
            zIndex: 1200,
        },
        boxShadow: 'none',
    }),
    toolbar: {
        justifyContent: 'space-between',
        backgroundColor: 'white',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        height: '64px',
    },
    logo: {
        height: '100%',
    },
};

interface TopBarProps {
    isLargeScreen?: boolean;
    onOpenSideBar?: () => void;
}

const TopBar = (props: TopBarProps) => {

    return (
        <AppBar position={'fixed'} color={'primary'} sx={styles.appBar}>
            <Toolbar sx={styles.toolbar}>
                <img
                    src={process.env.PUBLIC_URL + '/insecia.avif'}
                    alt={'Insecia logo'}
                    style={styles.logo}
                />
                {(!props.isLargeScreen) && (
                    <IconButton color={'primary'} onClick={props.onOpenSideBar}>
                        <MenuIcon />
                    </IconButton>
                )}
                {(props.isLargeScreen) && (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Tooltip title={'Logout'}>
                            <IconButton color={'primary'} onClick={Keycloak.logout}>
                                <LogoutIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default TopBar;
